@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
  box-sizing: border-box;
  transition: all ease 0.2s;
}

body {
  font-family: 'Lexend Deca', sans-serif;
}